import {mapGetters,mapActions} from 'vuex'
import Http from "@/service/http";
export const mixin = {
  components: {},

  data() {

  },

  computed: {
    ...mapGetters(['menuList','activeTopBar','buttonList']),
    activeName(){

    },
    activeIndex(){
      return this.activeTopBar
    },
    list(){
      let arr=[]
      this.menuList.forEach((item,idx)=>{
        if(this.activeIndex.route_name==item.route_name){
          arr=this.menuList[idx].children
        }
      })
      return arr

    }
  },

  watch: {},

  activated() {
  },

  mounted() {
  },

  destroyed() {
  },

  methods: {
    getBlur() {
      window.scroll(0, 0);
    },

  }
}
export  const pendingMixin = {

  components: {},
  data() {
    return{
      dialogVisible: false,

      userName:'',
    }
  },
  computed: {
    ...mapGetters(['menuList','activeTopBar','buttonList']),
    activeName(){

    },
  },
  watch: {},

  activated() {
  },

  mounted() {
  },

  destroyed() {
  },

  methods: {
    async updateHandleStatus(form){
      let res=await Http.updateHandleStatus(form)
      if(res.flag==1){
        this.$message.success(res.msg)
        this.getData()
      }
    },
    async addCommunication(dialogForm) {
      let res=await Http.addCommunicate(dialogForm)
      if(res.flag==1){
        this.$message.success(res.msg)
        this.handleClose()
        this.getData()
      }
    },
    showDialog(name, communicate_status, source_channel, id, a_id, identity_type,j_id,other_channel_desc) {
      this.dialogVisible = true
      this.userName = name
      if(communicate_status){
        this.dialogForm.communicate_status = communicate_status
      }
      if(source_channel){
        this.dialogForm.source_channel = source_channel
      }
      this.dialogForm.id = id
      this.dialogForm.a_id = a_id
      this.dialogForm.identity_type = identity_type
      this.dialogForm.j_id = j_id
      this.dialogForm.other_channel_desc=other_channel_desc

    },
    handleClose() {
      this.dialogVisible = false
      this.dialogForm.remark = ''
      this.dialogForm.other_channel_desc = ''
      if('source_channel' in this.dialogForm){
        this.dialogForm.source_channel = ''
      }
      if('communicate_status' in this.dialogForm){
        this.dialogForm.communicate_status = ''
      }

    },
    handleSelect(data) {
      let arr = []
      if (data.length > 0) {
        data.forEach((item) => {
          arr.push(item.id)
        })
      }
      this.selectForm.id = arr.toString()
    },
    checkSelectable(row) {
      return row.communicate_status != 0
    },
  }
}
export  const otherMixin={
  computed:{
    ...mapGetters(['saveDisabled'])
  },
  methods:{
    ...mapActions(['setDisabled']),
    goTo(name,query){
      this.$router.push({name,query})
    },
    goBack(){
      this.$router.back()
    }
  }
}

